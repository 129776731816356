<template>
<div>
    <b-row>
        <b-col md="6">
            <CCard>
                <CCardHeader color="dark" text-color="white">
                    <i class="fas fa-chart-bar fa-md"></i> <span class="h5"> Estadísticas</span>
                </CCardHeader>
                <CCardBody>
                    <span class="h6 text-muted">ESTADÍSTICAS DEL SISTEMA PARA LOS DOCUMENTOS COMERCIALES
                    </span>
                    <hr>
                    <b-row class="justify-content-center text-center">
                        <b-col cols="12" class=" my-2 icon-custom">
                            <i class="fas fa-folder-open fa-3x ico"> </i>
                            <br>
                            <span class="lg-numero">{{listaCarpetas.length}}</span>
                            <br>
                            <span class="text-muted h6">Carpetas</span>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>
        <b-col md="6">
            <CCard>
                <CCardHeader color="dark" text-color="white">
                    <i class="fas fa-cog fa-md"></i><span class="h5"> Gestión de carpetas </span>
                </CCardHeader>
                <CCardBody>
                    <b-row class="text-center justify-content-center">
                        <b-col md="6" class="my-2 ">
                            <b-button block size="md" variant="custom" @click="modalNuevaCarpeta = true">
                                <i class="fas fa-plus-circle fa-3x pb-1" style="inline-size: auto"></i>
                                <br>Nueva carpeta
                            </b-button>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>
        <b-col md="12">
            <CCard>
                <CCardHeader color="dark" text-color="white">
                    <i class="fas fa-list fa-md"></i> <span class="h5"> Carpetas registradas</span>
                </CCardHeader>
                <CCardBody>
                    <div>
                        <b-row>
                            <b-col lg="2">
                                <b-form-group label="Registros por página" class="text-muted">
                                    <b-form-select size="xl" v-model="porPagina" :options="paginaOpciones"></b-form-select>
                                </b-form-group>
                            </b-col>
                            <b-col lg="7">
                            </b-col>
                            <b-col lg="3">
                                <b-form-group label="Busqueda:" class="text-muted">
                                    <b-input-group size="xl">
                                        <b-input-group-prepend is-text>
                                            <i class="fas fa-search  my-0 mx-0"></i>
                                        </b-input-group-prepend>
                                        <b-form-input type="search" v-model="filter" id="filterInput" placeholder="Buscar..."></b-form-input>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                            <b-col md="12">
                                <b-table :items="listaCarpetas" :fields="camposCarpetas" :current-page="currentPage" :per-page="porPagina" :filter="filter" :filter-included-fields="filterOn" bordered hover show-empty mediun responsive outlined @filtered="onFiltered" empty-text="Aún no hay datos en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                    <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                    <template #cell(acciones)="param">
                                        <b-button  :to="{name: 'archivos master', params: {id: param.item.idCarpeta, nombre: param.item.nombre} }"  class="mr-1 mb-1" size="sm" variant="blue" v-c-tooltip="'Abrir carpeta'">
                                            <i class="fas fa-folder-open my-0 mx-0"></i>
                                        </b-button>
                                        <b-button @click="abrirModalModificarCarpeta(param)" class="mr-1 mb-1" size="sm" variant="dark" v-c-tooltip="'Ver detalles'">
                                            <i class="fas fa-search my-0 mx-0"></i>
                                        </b-button>
                                        <b-button @click="eliminarCarpeta(param)" class="mr-1 mb-1" size="sm" variant="danger" v-c-tooltip="'Eliminar'">
                                            <i class="fas fa-trash-alt  my-0 mx-0"></i> 
                                        </b-button>
                                    </template>
                                </b-table>
                            </b-col>
                        </b-row>
                    </div>
                </CCardBody>
                <CCardFooter>
                    <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="porPagina" align="right" size="sm" class="my-0"></b-pagination>
                </CCardFooter>
            </CCard>
        </b-col>
    </b-row>
    <CModal :closeOnBackdrop="false" color="dark" :show.sync="modalNuevaCarpeta">
        <template #header>
            <h6 class="modal-title">
                <i class="fas fa-plus-square fa-md"></i>
                <span class="h5"> Nueva carpeta </span>
            </h6>
            <CButtonClose @click="modalNuevaCarpeta = false" class="text-white" />
        </template>

        <validation-observer ref="observer2" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(registrarCarpeta)">
                <b-row>
                    <b-col md="12">
                        <validation-provider name="nombre" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Nombre:" class="mb-2">
                                <b-form-input type="text" :state="getValidationState(validationContext)" size="md" placeholder="Ingrese el nombre" v-model="datosNuevaCarpeta.nombre"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="12">
                        <validation-provider name="descripción" v-slot="validationContext">
                            <b-form-group label="Descripción:" class="mb-2">
                                <b-form-textarea type="text" :state="getValidationState(validationContext)" size="md" rows="3" max-rows="6" placeholder="Ingrese la descripción" v-model="datosNuevaCarpeta.descripcion"></b-form-textarea>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col class="my-2 text-right" md="12">
                        <b-button :disabled="disabled" size="md" variant="success" class=" mr-2" type="submit">
                            Guardar
                        </b-button>
                        <b-button size="md" variant="danger" @click="modalNuevaCarpeta = false">
                            Cancelar
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>
        <template #footer>
            <span></span>
        </template>
    </CModal>

    <CModal :closeOnBackdrop="false" color="dark" :show.sync="modalModificarCarpeta">
        <template #header>
            <h6 class="modal-title">
                <i class="fas fa-edit fa-md"></i>
                <span class="h5"> Actualizar carpeta </span>
            </h6>
            <CButtonClose @click="modalModificarCarpeta = false" class="text-white" />
        </template>

        <validation-observer ref="observer3" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(modificarCarpeta)">
                <b-row>
                    <b-col md="12">
                        <validation-provider name="nombre" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Nombre:" class="mb-2">
                                <b-form-input type="text" :state="getValidationState(validationContext)" size="md" placeholder="Ingrese el nombre" v-model="datosModificarCarpeta.nombre"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="12">
                        <validation-provider name="descripción" v-slot="validationContext">
                            <b-form-group label="Descripción:" class="mb-2">
                                <b-form-textarea type="text" :state="getValidationState(validationContext)" size="md"  placeholder="Ingrese la descripción"  rows="3" max-rows="6" v-model="datosModificarCarpeta.descripcion"></b-form-textarea>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col class="my-2 text-right" md="12">
                        <b-button :disabled="disabled" size="md" variant="success" class=" mr-2" type="submit">
                            Guardar
                        </b-button>
                        <b-button size="md" variant="danger" @click="modalModificarCarpeta = false">
                            Cancelar
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>
        <template #footer>
            <span></span>
        </template>
    </CModal>
</div>
</template>

<script>
import firebase from 'firebase';
const dbCarpeta = firebase.firestore().collection('carpetas');

export default {

    data() {
        return {
            datosSession: {
                idCliente: 0
            },

            modalNuevaCarpeta: false,
            modalModificarCarpeta: false,

            totalRows: 1,
            currentPage: 1,
            porPagina: 10,
            filter: null,
            filterOn: [],
            paginaOpciones: [10, 25, 50, 100, {
                value: 9999999,
                text: "Mostrar todos"
            }],

            camposCarpetas: [{
                    key: "index",
                    label: "N°",
                    class: "text-center",
                },
                {
                    key: "nombre",
                    label: "Nombre",
                    class: "text-center",
                },
                {
                    key: "fechaCreacion",
                    label: "Creación",
                    class: "text-center",
                },
           
                {
                    key: "cantidadDocumentos",
                    label: "Documentos",
                    class: "text-center",
                },
                {
                    key: "fechaModificacion",
                    label: "Última modificación",
                    class: "text-center",
                },
                {
                    key: "acciones",
                    label: "",
                    class: "text-center"
                }
            ],

            listaCarpetas: [],

            datosNuevaCarpeta: {
                idCarpeta: '',
                nombre: '',
                descripcion: ''
            },
            datosModificarCarpeta: {
                idCarpeta: '',
                nombre: '',
                descripcion: ''
            },
            disabled: false
        }
    },
    methods: {
        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },

        registrarCarpeta() {
            let me = this;
            me.disabled = true;
            dbCarpeta.add({
                    nombre: me.datosNuevaCarpeta.nombre,
                    descripcion: me.datosNuevaCarpeta.descripcion,
                    estado: 2,
                    fechaCreacion: firebase.firestore.Timestamp.fromDate(new Date()),
                    fechaModificacion: firebase.firestore.Timestamp.fromDate(new Date()),
                })
                .then((docRef) => {
                    //console.log("Creado correctamente ID: ", docRef.id);
                    me.swat('success', 'Registrado satisfactoriamente')
                    me.modalNuevaCarpeta = false
                    me.disabled = false;
                })
                .catch((error) => {
                    me.disabled = false;
                    me.swat('error', 'Algo salió mal!')
                });
        },
        modificarCarpeta() {
            let me = this;
            me.disabled = true;
            dbCarpeta.doc(me.datosModificarCarpeta.idCarpeta).update({
                    nombre: me.datosModificarCarpeta.nombre,
                    descripcion: me.datosModificarCarpeta.descripcion,
                    fechaModificacion: firebase.firestore.Timestamp.fromDate(new Date()),
                })
                .then(() => {
                    //console.log("Actualizado correctamente");
                    me.swat('success', 'Modificado satisfactoriamente')
                    me.modalModificarCarpeta = false
                    me.disabled = false;
                })
                .catch((error) => {
                    me.disabled = false;
                    me.swat('error', 'Algo salió mal!')
                });
        },

        listarCarpetas() {
            let me = this;
            dbCarpeta.where("estado", "==", 2)
                .onSnapshot((querySnapshot) => {
                    me.listaCarpetas = [];
                    querySnapshot.forEach((doc) => {
                        me.listaCarpetas.push({
                            idCarpeta: doc.id,
                            nombre: doc.data().nombre,
                            descripcion: doc.data().descripcion,
                            estado: doc.data().estado,
                            fechaCreacion: moment(doc.data().fechaCreacion.toDate()).format('YYYY-MM-DD'),
                            fechaModificacion: moment(doc.data().fechaModificacion.toDate()).format('YYYT-MM-DD'),
                        });
                    });
                    me.listaCarpetas.sort((a,b) => (a.fechaCreacion > b.fechaCreacion) ? -1 : ((b.fechaCreacion > a.fechaCreacion) ? 1 : 0))
                    
                });
        },

        abrirModalModificarCarpeta(param) {
            let me = this;
            me.datosModificarCarpeta.nombre = param.item.nombre;
            me.datosModificarCarpeta.descripcion = param.item.descripcion;
            me.datosModificarCarpeta.idCarpeta = param.item.idCarpeta;

            me.modalModificarCarpeta = true;
        },
        resetModalNuevaCarpeta() {
            this.$nextTick(() => {
                this.$refs.observer2.reset();
            });
            this.datosNuevaCarpeta.nombre = '';
            this.datosNuevaCarpeta.descripcion = '';
        },
        resetModalModificarCarpeta() {
            this.$nextTick(() => {
                this.$refs.observer3.reset();
            });
        },
        eliminarCarpeta(param) {
            let me = this
            me.$swal.fire({
                title: '¿Estas seguro de eliminar la carpeta?',
                text: "¡No podrás revertir esto!",
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#d33',
                confirmButtonText: 'Eliminar',
            }).then((result) => {
                if (result.isConfirmed) {
                    dbCarpeta.doc(param.item.idCarpeta).update({
                            estado: 1,
                        })
                        .then(() => {
                            me.swat('success', 'Eliminado satisfactoriamente')
                        })
                        .catch((error) => {
                            me.swat('error', 'Algo salió mal!')
                        });
                }
            })
        },
        swat(icon, title) {
            this.$swal.mixin({
                toast: true,
                showConfirmButton: false,
                position: 'bottom-right',
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            }).fire({
                icon: icon,
                title: title
            });
        }
    },
    computed: {

    },
    watch: {
        modalNuevaCarpeta: function (val) {
            if (val == false) {
                this.resetModalNuevaCarpeta();
            }
        },
        modalModificarCarpeta: function (val) {
            if (val == false) {
                this.resetModalModificarCarpeta();
            }
        }
    },
    mounted() {
        this.listarCarpetas();
    }

}
</script>
